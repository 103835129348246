import { MapContainer, Popup, FeatureGroup, Rectangle } from 'react-leaflet'
import {CRS} from 'leaflet';
import React from 'react';
import landsEconZones from "./data/solarwoodEconomies.json";

const landPlotOptions = { color: '#45527C' };
const settlementPlotOptions = { color: '#4FA6F8' };
const townPlotOptions = { color: '#A63DFF' };
const cityPlotOptions = { color: '#D13073' };
const capitalPlotOptions = { color: '#E7985D' };
const defaultPlotOptions = { color: 'black' };

class SolarEcon extends React.Component {

  render () {
    return (
      <div className="SolarEcon">
        <MapContainer center={[100, 100]} zoom={8} scrollWheelZoom={true} crs={CRS.Simple}>
        {landsEconZones.map(land => 
          <FeatureGroup pathOptions={
            land.economicScore === 1 
            ? landPlotOptions
            : land.economicScore === 2  
            ? settlementPlotOptions
            : land.economicScore === 3
            ? townPlotOptions
            : land.economicScore === 4 
            ? cityPlotOptions
            : land.economicScore > 4 
            ? capitalPlotOptions
            : defaultPlotOptions}>
            <Popup>
              {land.name}
              <br/>
              {land.economicScore}
              <br/>
              {land.economicZones.map(zone =>
              <React.Fragment>
                {zone.Type}
                <br/>
              </React.Fragment>
              )}
            </Popup>
            <Rectangle bounds={[[land.attributes[2].value+.4, land.attributes[1].value-.4],[land.attributes[2].value-.4, land.attributes[1].value+.4]]} />
          </FeatureGroup>
        )}

        </MapContainer>
      </div>
    );
  }
}
  
export default SolarEcon;
  