import './App.css';
// import Solarwood from './Solarwood';
import SolarEcon from './SolarEcon';

function App() {
  return (
    <div className="App">
      <SolarEcon/>
    </div>
  );
}

export default App;
